<template>
  <v-card class="ma-3">
    <page-overlay :overlay="isOverlay"/>
    <v-row>
      <v-col cols="12" md="12">
        <v-col cols="12" md="12">
          <v-card class="mx-auto">
            <v-card-title>Cart Details</v-card-title>
            <v-divider/>
            <v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="setCarts(salesCounters.sales_counter_details)"
                  hide-default-footer
                  :disable-pagination="true"
              >
                <template v-slot:item.liveLink={item}>
                  <v-chip
                      class="ma-2"
                      color="green"
                      link
                      text-color="white"
                      @click.prevent.stop="liveLinkHandler(item)"
                  >
                    View Live Link
                  </v-chip>
                </template>
                <template v-slot:item.replaceLiveLink={item}>
                  <v-chip
                      v-if="item.replaceLiveLink"
                      class="ma-2"
                      color="green"
                      link
                      text-color="white"
                      @click.prevent.stop="liveLinkHandler(item, 'replaced')"
                  >
                    View Live Link
                  </v-chip>
                </template>
                <template v-slot:item.isAllowedLinkInsert={item}>
                  {{item.isAllowedLinkInsert ? 'Yes' : 'No'}}
                </template>
                <template v-slot:item.linkInsertions={item}>
                  <v-chip
                      v-if="salesCounters.billingType === 'Link Insertion on' || salesCounters.billingType === 'Home Page Link Insertion on'"
                      class="ma-2"
                      color="lime"
                      link
                      text-color="white"
                      @click.prevent.stop="linkInsertionPopupHandler(item)"
                  >
                    View Links
                  </v-chip>
                </template>
                <template v-slot:item.price={item}>
                  ${{ item.price }}
                </template>
                <template v-slot:item.adultPrice={item}>
                  ${{ item.adultPrice }}
                </template>
                <template v-slot:item.articleWritingPrice={item}>
                  ${{ item.articleWritingPrice }}
                </template>
                <template v-slot:item.discount={item}>
                  ${{ item.discount }}
                </template>
                <template v-slot:item.total={item}>
                  ${{ item.total }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>Order Details</v-card-title>
                  <v-divider/>
                  <v-list dense>
                    <list-item-details symbol="#" title="Order ID" :value="salesCounters.orderId"/>
                    <list-item-details title="Billing Type" :value="salesCounters.billingType"/>
                    <list-item-details title="Email" :value="salesCounters.email"/>
                    <list-item-details title="Order Date" :value="getDate(salesCounters.date)"/>
                    <list-item-prepayment-status :status="salesCounters.isPrepayment"/>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>Payment Details</v-card-title>
                  <v-divider/>
                  <v-list dense>
                    <list-item-payment-status title="Status" :status="salesCounters.paymentStatus"
                                              :isDeleted="salesCounters.isDeleted"/>
                    <list-item-details title="Payment Date" :value="getDate(salesCounters.paymentDate)"/>
                    <list-item-details symbol="$" title="Total" :value="salesCounters.amount"/>
                    <list-item-details symbol="-$" title="Discount" :value="salesCounters.discount"/>
                    <list-item-details symbol="-$" title="Invoice Discount" :value="salesCounters.invoiceDiscount"/>
                    <list-item-details symbol="$" title="Grand Total" :value="salesCounters.totalAmount"/>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="6">
                <v-card class="mx-auto">
                  <v-card-title>Who is Created?</v-card-title>
                  <v-divider/>
                  <v-list dense>
                    <list-item-details title="Name" :value="checkUser(salesCounters.user)"/>
                    <list-item-details title="Email" :value="checkUser(salesCounters.user, 'email')"/>
                    <list-item-details title="Date" :value="getDate(salesCounters.createdAt)"/>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="mx-auto">
                  <v-card-title>Who is Updated?</v-card-title>
                  <v-divider/>
                  <v-list dense>
                    <list-item-details title="Name" :value="checkUser(salesCounters.updater)"/>
                    <list-item-details title="Email" :value="checkUser(salesCounters.updater, 'email')"/>
                    <list-item-details title="Date" :value="getDate(salesCounters.updatedAt)"/>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="mx-auto">
                  <v-card-title>Who is Deleted?</v-card-title>
                  <v-divider/>
                  <v-list dense>
                    <list-item-details title="Name" :value="checkUser(salesCounters.deleter)"/>
                    <list-item-details title="Email" :value="checkUser(salesCounters.deleter, 'email')"/>
                    <list-item-details title="Date" :value="getDate(salesCounters.deletedAt)"/>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="mx-auto">
                  <v-card-title>Who is Restored?</v-card-title>
                  <v-divider/>
                  <v-list dense>
                    <list-item-details title="Name" :value="checkUser(salesCounters.restorer)"/>
                    <list-item-details title="Email" :value="checkUser(salesCounters.restorer, 'email')"/>
                    <list-item-details title="Date" :value="getDate(salesCounters.restoredAt)"/>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <view-live-link
        :item="currentItem"
        :show="showLiveLink"
        :disabled="true"
        @closePopupHandler="closePopupHandler"
    />
    <link-insertions-popup
        :item="currentItem"
        :show="showLinkInsertionPopup"
        :disabled="true"
        @closePopupHandler="closePopupHandler"
    />
  </v-card>
</template>

<script>
import ListItemDetails from "../../components/details/ListItemDetails";
import DateHelper from "../../components/mixins/DateHelper";
import ListItemPaymentStatus from "../../components/details/ListItemPaymentStatus";
import ListItemPrepaymentStatus from "../../components/details/ListItemPrepaymentStatus";
import ViewLiveLink from "../../components/popups/ViewLiveLink";
import LinkInsertionsPopup from "../../components/popups/LinkInsertionsPopup";
import PageOverlay from "../../components/PageOverlay";

export default {
  name: "SaleDetails",
  mixins: [DateHelper],
  components: {
    PageOverlay,
    LinkInsertionsPopup,
    ViewLiveLink,
    ListItemPrepaymentStatus,
    ListItemPaymentStatus,
    ListItemDetails
  },
  data() {
    return {
      salesCounterId: this.$route.params.id,
      salesCounters: {},
      showLiveLink: false,
      showLinkInsertionPopup: false,
      currentItem: null,
      isOverlay: false,
      headers: [
        {
          text: 'Site URL',
          value: 'site.site_url'
        },
        {
          text: 'Entity Name',
          value: 'customEntityName'
        },
        {
          text: 'Live Link',
          value: 'liveLink'
        },
        {
          text: 'R. Site Url',
          value: 'replaced_site.site_url'
        },
        {
          text: 'R. Live Link',
          value: 'replaceLiveLink'
        },
        {
          text: 'Link Insertions',
          value: 'linkInsertions'
        },
        {
          text: 'link insert?',
          value: 'isAllowedLinkInsert'
        },
        {
          text: 'Price',
          value: 'price',
          align: 'right'
        },
        {
          text: 'Adult Price',
          value: 'adultPrice',
          align: 'right'
        },
        {
          text: 'A. W. Price',
          value: 'articleWritingPrice',
          align: 'right'
        },
        {
          text: 'Discount',
          value: 'discount',
          align: 'right'
        },
        {
          text: 'Total',
          value: 'total',
          align: 'right'
        }
      ]
    }
  },
  mounted() {
    this.getSalesDetails();
  },
  methods: {
    async getSalesDetails() {
      try {
        this.isOverlay = true;
        const {data: {data}} = await this.$store.dispatch('salesCounter/get', {id: this.salesCounterId});
        this.salesCounters = data.item;
        this.isOverlay = false;
      } catch (error) {
        this.isOverlay = false;
        console.log(error);
      }
    },
    checkUser(user, type = 'name') {
      if (!user) return '';
      return user[type];
    },
    setCarts(items) {
      if (!items) return [];
      return items.filter((data) => data.isActive);
    },
    liveLinkHandler(item, type = 'liveLink') {
      let data = {...item};
      if (type === 'replaced') {
        data.liveLink = data.replaceLiveLink;
      }
      this.showLiveLink = true;
      this.currentItem = data;
    },
    linkInsertionPopupHandler(item) {
      const data = this.getLinkInsertions(item);

      this.showLinkInsertionPopup = true;
      this.currentItem = {linkInsertions: data};
    },
    closePopupHandler() {
      this.showLiveLink = false;
      this.showLinkInsertionPopup = false;
      this.currentItem = null;
    },
    getLinkInsertions(item) {
      const {
        anchorText1,
        url1,
        anchorText2,
        url2,
        anchorText3,
        url3,
        anchorText4,
        url4,
        anchorText5,
        url5,
        anchorText6,
        url6,
        anchorText7,
        url7,
        anchorText8,
        url8,
        anchorText9,
        url9,
        anchorText10,
        url10
      } = item;

      let arr = []

      if (anchorText1) {
        arr.push({anchorText: anchorText1, url: url1})
      }

      if (anchorText2) {
        arr.push({anchorText: anchorText2, url: url2})
      }

      if (anchorText3) {
        arr.push({anchorText: anchorText3, url: url3})
      }

      if (anchorText4) {
        arr.push({anchorText: anchorText4, url: url4})
      }

      if (anchorText5) {
        arr.push({anchorText: anchorText5, url: url5})
      }

      if (anchorText6) {
        arr.push({anchorText: anchorText6, url: url6})
      }

      if (anchorText7) {
        arr.push({anchorText: anchorText7, url: url7})
      }

      if (anchorText8) {
        arr.push({anchorText: anchorText8, url: url8})
      }

      if (anchorText9) {
        arr.push({anchorText: anchorText9, url: url9})
      }

      if (anchorText10) {
        arr.push({anchorText: anchorText10, url: url10})
      }

      return arr;
    }
  }
}
</script>

<style scoped>

</style>